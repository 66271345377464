import { getCookieStorage } from '~/common/utils';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { defaultBackend } from './root/PreviewSwitcher/backends';
const COOKIE_USE_BE = getCookieStorage(document.cookie).use_be;
export const PUSHER_KEY = process.env.REACT_APP_PUSHER_KEY;
export const POSTHOG_HOST = process.env.REACT_APP_POSTHOG_HOST;
export const POSTHOG_KEY = process.env.REACT_APP_POSTHOG_KEY;
export const SERVER_URL = COOKIE_USE_BE || process.env.REACT_APP_SERVER_URL || defaultBackend;
export const WS_URL = COOKIE_USE_BE || process.env.REACT_APP_WS_URL || defaultBackend;
export const ENV = {
    AS_STRING: process.env.REACT_APP_ENV,
    PRODUCTION: process.env.REACT_APP_ENV === 'production',
    STAGING: process.env.REACT_APP_ENV === 'staging',
    DEVELOPMENT: process.env.REACT_APP_ENV === 'development',
};
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;
export const SENTRY_RELEASE = process.env.REACT_APP_SENTRY_RELEASE;
export const SENTRY_TRACES_SAMPLE_RATE = Number(process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE) || 0;
export const SENTRY_REPLAY_SESSION_RATE = Number(process.env.REACT_APP_SENTRY_REPLAY_SESSION_RATE) || 0;
export const TESTING = Boolean(localStorage.getItem('_testing'));
// there's no point in using minor and patch versions, because updating BE
// does not always result in FE updates, and FE updates does not always result
// in BE updates
//
// we should instead rely on what hashes are in the index.html file, like
// refetch it once in a while or something...
export const VERSION = 8;
export const CLIENT_VERSION = process.env.REACT_APP_COMMIT_TIMESTAMP;
