import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faExclamationTriangle, faFile, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { faCloudUpload } from '@fortawesome/pro-solid-svg-icons';
import { useEffect } from 'react';
import { Button, FileInput, ProgressBar } from '~/common/components';
import { useFileUploadProgress } from '~/orders/hooks';
import { FileItem } from '../../FilesSection';
// TODO these file upload components feel kinda nested-dolly and annoying to work with
const allowedExtensions = [
    // media
    'jpeg',
    'jpg',
    'png',
    // power point
    'ppt',
    'pot',
    'pps',
    'ppa',
    'pptx',
    'potx',
    'ppsx',
    'ppam',
    'pptm',
    'potm',
    'ppsm',
    // microsoft word
    'doc',
    'dot',
    'docx',
    'dotx',
    'docm',
    'dotm',
    // microsoft excel
    'xls',
    'xlt',
    'xla',
    'xlsx',
    'xltx',
    // other
    'txt',
    'pdf',
    'zip',
    '7z',
    'rar',
    'key',
    'ai',
    'zipx',
    'rtf',
    'pub',
    'psd',
    'cdr',
    // fonts
    'ttf',
    'txt',
    'eps',
    'pdf',
    'woff',
    'woff2',
    'otf',
]
    .map((ext) => `.${ext}`)
    .join(',');
const today = new Date();
const FileUploading = ({ fileToUpload: file, onFileUpload, onFileRemove, signal, warningText, }) => {
    const fileUpload = useFileUploadProgress({
        file,
        tag: 'order_revision',
        onSuccess: onFileUpload,
        signal,
    });
    useEffect(() => {
        fileUpload.mutate();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fileUpload.mutate]);
    return (_jsxs("div", { className: "flex flex-col justify-center gap-1", children: [_jsxs("div", { className: "flex items-center gap-2", children: [_jsx(FileItem, { icon: fileUpload.error || warningText ? faExclamationTriangle : faFile, name: file.name, date: today, tooltipText: fileUpload.error || warningText, warning: !!warningText }), _jsx(Button.Icon, { color: "grey-dark", icon: faTimes, onClick: onFileRemove })] }), _jsx(ProgressBar, { progress: fileUpload.progress, max: 100 })] }));
};
const UploadFile = ({ fileToUpload, onFileSelection, warningText, ...fileUploadingMethods }) => {
    if (fileToUpload) {
        return (_jsx(FileUploading, { fileToUpload: fileToUpload, warningText: warningText, ...fileUploadingMethods }));
    }
    return (_jsx(FileInput, { accept: allowedExtensions, labelClassName: "flex items-center gap-1 hover:cursor-pointer", onChoose: (files) => {
            const file = files[0];
            onFileSelection(file);
        }, children: _jsx(Button.Div, { color: "text-secondary", size: "xs", icon: faCloudUpload, children: "Upload file" }) }));
};
export const RevisionFile = ({ file, editable = false, onFileRemove, warningText, ...uploadFileProps }) => {
    if (!file) {
        return (_jsx(UploadFile, { ...uploadFileProps, onFileRemove: onFileRemove, warningText: warningText }));
    }
    return (_jsxs("div", { className: "flex items-center gap-1", children: [_jsx(FileItem, { icon: warningText ? faExclamationTriangle : faFile, name: file.name, date: file.date, tooltipText: warningText, warning: !!warningText }), editable && _jsx(Button.Icon, { color: "grey-dark", icon: faTimes, onClick: onFileRemove })] }));
};
