import { jsx as _jsx } from "react/jsx-runtime";
import { QueryClientProvider } from '@tanstack/react-query';
import React from 'react';
import ReactDOM from 'react-dom';
import { queryClient, Root } from '~/root';
import '~/styles/index.scss';
import '~/styles/tailwind.scss';
import './hacks';
export const AppShell = (...modules) => {
    ReactDOM.render(_jsx(React.StrictMode, { children: _jsx(QueryClientProvider, { client: queryClient, children: _jsx(Root, { modules: modules }) }) }), document.getElementById('root'));
};
