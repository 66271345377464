import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef } from 'react';
import { cx } from '~/common/utils';
import { FormElementLabel } from '../FormElementLabel';
import { IconBox } from '../IconContainers';
import styles from './Input.module.scss';
export const Input = forwardRef(({ className, icon, noIconBorder, size = 'regular', error, textAlignment = 'left', title, hint, hintClickable, optional, children, theme, square, ...props }, ref) => {
    // this is needed to prevent annoying input blurring and re-focusing
    const handleMouseDown = (e) => e.preventDefault();
    return (_jsxs("label", { ref: ref, className: cx(styles.wrapper, textAlignment !== 'left' && styles[textAlignment], {
            [styles.small]: size === 'small',
            [styles.hasIcon]: icon,
            [styles.noIconBorder]: noIconBorder,
            [styles.hasChildren]: children,
            [styles.error]: error,
            [styles.disabled]: props.disabled,
            [styles.readOnly]: props.readOnly,
            [styles.themeFilter]: theme === 'filter',
            [styles.themeDark]: theme === 'dark',
            [styles.squareLeft]: square === 'left',
            [styles.squareRight]: square === 'right',
        }, className), children: [_jsx(FormElementLabel, { title: title, optional: optional, hint: hint, hintClickable: hintClickable }), _jsxs("div", { className: styles.inputWrapper, children: [icon && (_jsx(IconBox, { size: "m", className: styles.icon, icon: icon, onMouseDown: handleMouseDown })), _jsx("input", { ...props, className: styles.input }), children && (_jsx("div", { onMouseDown: handleMouseDown, className: styles.children, children: children }))] })] }));
});
