import { isNonEmpty } from 'formoid';
import { array, boolean, eq, number, ord, string } from 'fp-ts';
import { pipe } from 'fp-ts/function';
import { z } from 'zod';
import { UserAvatarInfo } from '~/common/kits/usercard';
import { bidirectionalMap, DateFromIsoString, Email, Link, NonNegativeInteger, NonNegativeNumber, TimelessDateFromIsoString, } from '~/common/utils';
import { formatTimeInStatus } from '~/orders/utils';
import { Bounty, CardBrand, CustomPortalStyle, CustomStyle, DefaultStyle, Label, Metadata, NumericIdNamePair, StringIdNamePair, UploadedFile, } from '~/root';
import { Revisions } from './revisions';
/**
 * OrderStatus
 */
export const OrderStatusMap = bidirectionalMap.make({
    New: 11,
    Open: 1,
    Approved: 3,
    DesignInProgress: 4,
    ReadyForReview: 12,
    ReadyToSend: 13,
    WaitingForCustomer: 5,
    FeedbackReceived: 14,
    Completed: 6,
    Cancelled: 7,
});
/**
 * Can be used for things like "display something if order status is after DesignInProgress"
 *
 * const status: OrderStatus = 'Approved';
 * const showIfAfterDesignInProgress = ord.geq(ordOrderStatus)(status, 'DesignInProgress');
 */
export const ordOrderStatus = pipe(number.Ord, ord.contramap((status) => {
    switch (status) {
        case 'New':
            return 1;
        case 'Open':
            return 2;
        case 'Approved':
            return 3;
        case 'DesignInProgress':
            return 4;
        case 'ReadyForReview':
            return 5;
        case 'ReadyToSend':
            return 6;
        case 'WaitingForCustomer':
            return 7;
        case 'FeedbackReceived':
            return 8;
        case 'Completed':
            return 9;
        case 'Cancelled':
            return 10;
    }
}));
export const OrderStatus = z
    .union([
    z.literal(1),
    z.literal(3),
    z.literal(4),
    z.literal(5),
    z.literal(6),
    z.literal(7),
    z.literal(11),
    z.literal(12),
    z.literal(13),
    z.literal(14),
])
    .transform((id) => OrderStatusMap.reverse[id]);
export const OrderStatusEncoder = {
    encode: (status) => OrderStatusMap.forward[status],
};
export const OrderStatusOption = z
    .object({
    id: OrderStatus,
    label: z.string(),
    transitions: z.array(OrderStatus).nonempty().nullable(),
})
    .transform(({ id, label, transitions }) => ({
    name: label,
    transitions,
    value: id,
}));
/**
 * LegacyOrderStatus
 */
export const OrderStatusLegacyMap = bidirectionalMap.make({
    FollowedUp: 2,
    CompletedInvoce: 8,
    CompletedPayment: 9,
    Rejected: 10,
});
export const OrderStatusLegacy = z
    .union([z.literal(2), z.literal(8), z.literal(9), z.literal(10)])
    .transform((id) => OrderStatusLegacyMap.reverse[id]);
export const OrderStatusLegacyOption = z
    .object({
    id: OrderStatusLegacy,
    label: z.string(),
    transitions: z.literal(null),
})
    .transform(({ id, label, transitions }) => ({
    name: label,
    transitions,
    value: id,
}));
export const Addon = z
    .object({
    id: z.number(),
    name: z.string(),
})
    .transform(({ id, name }) => ({ name, value: id }));
export const Brief = z.object({
    brief: z.string(),
    custom: z.record(z.string().nullable()),
    questions: z.union([
        z.record(z.string().nullable()),
        // well done php, sending [] as empty objects
        z
            .any()
            .array()
            .transform(() => null),
    ]),
});
/* Iterations */
export const DistributionItem = z.object({
    date: TimelessDateFromIsoString,
    points: z.number(),
    points_max: z.number(),
    is_holiday: z.boolean(),
});
export const IterationDesigner = z
    .object({
    distribution: z.array(DistributionItem),
    id: z.number(),
    name: z.string(),
})
    .transform((iterationDesigner) => ({
    ...iterationDesigner,
    distribution: new Map(iterationDesigner.distribution.map((item) => [item.date, item.points])),
    // making it this way won't require changes to distribution update logic
    points_max_data: iterationDesigner.distribution.map((item) => item.points_max),
    is_holiday_data: iterationDesigner.distribution.map((item) => item.is_holiday),
}));
export const IterationTeam = z.object({
    designers: z
        .array(IterationDesigner)
        .transform((designers) => new Map(designers.map((designer) => [designer.id, designer]))),
    id: z.number(),
    name: z.string(),
    type: z.enum(['primary', 'tagTeam']),
});
const IterationItemBase = z.object({
    dates: z.array(TimelessDateFromIsoString),
    id: z.number().int().positive(),
    name: z.string(),
    points: z.number(),
    points_max: z.number(),
    teams: z.array(IterationTeam).transform((teams) => new Map(teams.map((team) => [team.id, team]))),
});
const FirstDraftIteration = IterationItemBase.extend({ type: z.literal('firstDraft') });
const RevisionIteration = IterationItemBase.extend({
    type: z.literal('revision'),
    reason: z.string().nullable(),
});
export const IterationItem = z.union([FirstDraftIteration, RevisionIteration]);
/* Iterations */
export const Links = z.object({
    pm_overview: Link,
    trello_url: Link.nullable(),
    customer_capacity_overview: Link,
});
export const PaymentStatus = z.enum(['paid', 'unpaid', 'priceless']);
export const Payment = z.object({
    amount: z.number(),
    autocharge: z.boolean(),
    coupon: z.string().nullable(),
    currency: z.string(),
    discount: Bounty.nullable(),
    invoice: Link.nullable(),
    status: PaymentStatus,
    unit: z.number().nonnegative(),
    vat: z.number().nonnegative(),
});
export const Manager = NumericIdNamePair;
export const Designer = NumericIdNamePair;
export const DesignTeam = z
    .object({
    id: z.number(),
    designers: z.array(Designer),
    name: z.string(),
    office_id: z.number(),
})
    .transform(({ id, ...team }) => ({ value: id, ...team }));
export const AssignedDesigner = z
    .object({
    id: z.number(),
    name: z.string(),
    points: z.number().int().nonnegative(),
})
    .transform(({ id, ...rest }) => ({ ...rest, value: id }));
export const AssignedDesignTeam = z.object({
    id: z.number(),
    designers: z.array(AssignedDesigner),
    points: z.number().int().nonnegative(),
});
export const TimeLineUser = z.object({
    name: z.string(),
    initials: z.string(),
});
export const TimeLineItem = z
    .object({
    date: DateFromIsoString,
    statusFrom: z.union([OrderStatus, OrderStatusLegacy]),
    statusTo: z.union([OrderStatus, OrderStatusLegacy]),
    timeInStatus: NonNegativeInteger,
    user: TimeLineUser.nullable().transform((user) => user !== null && user !== void 0 ? user : { name: 'unknown', initials: 'UN' }),
})
    .transform(({ timeInStatus, ...timeLineItem }) => ({
    ...timeLineItem,
    timeInStatus: formatTimeInStatus(timeInStatus, timeLineItem.date),
}));
export const Treatment = z.object({
    id: z.number(),
    name: z.string(),
    value: z.number(),
});
export const eqTreatment = eq.struct({
    id: number.Eq,
    name: string.Eq,
    value: number.Eq,
});
export const eqTreatments = array.getEq(eqTreatment);
const getTreatmentOrdering = (treatment) => {
    switch (treatment.name.toLowerCase()) {
        case 'fix up':
            return 1;
        case 'redesign':
            return 2;
        case 'redraw':
            return 3;
        case 'let us decide':
            return 4;
        case 'additional design services':
            return 5;
        default:
            return 6;
    }
};
const ordTreatment = pipe(number.Ord, ord.contramap(getTreatmentOrdering));
function isRating(value) {
    return value <= 5 && value % 0.5 === 0;
}
const Rating = NonNegativeNumber.refine(isRating);
const CustomerFeedback = z.object({
    ratings: z.record(Rating.nullable()),
    qa: z.record(z.string().nullable()),
});
const CustomerCapacity = z.object({
    daily: z.number(),
    end: TimelessDateFromIsoString.nullable(),
    start: TimelessDateFromIsoString.nullable(),
});
export const FollowersUpdated = z.object({
    order_id: z.number(),
    followers: z.array(UserAvatarInfo),
});
const BasicSelectedStyle = z.object({
    id: z.number(),
    name: z.string(),
    is_enabled: z.boolean(),
});
const SelectedCustomerStyle = BasicSelectedStyle.extend({
    style_type: z.literal('customer_styles'),
    file: UploadedFile,
    preview: UploadedFile,
}).transform((value) => ({
    ...value,
    // we're using string id's in forms to avoid selected option matching due to
    // referential equality
    id: `${value.style_type}.${value.id}`,
    file_url: value.file.link,
    preview_url: value.preview.link,
}));
const SelectedLegacyStyle = BasicSelectedStyle.extend({
    style_type: z.literal('styles'),
    file_url: Link.optional(),
    preview_url: Link.optional(),
}).transform((value) => ({
    ...value,
    id: `${value.style_type}.${value.id}`,
}));
const SelectedStyle = z.union([SelectedCustomerStyle, SelectedLegacyStyle]);
export const BaseOrder = z.object({
    addons: z.array(Addon),
    brief: Brief,
    can_unfollow: z.boolean(),
    cancellation_reason: z.string().nullable(),
    country: z.string().nullable(),
    created: DateFromIsoString,
    customer_capacity: CustomerCapacity.nullable(),
    deadline: DateFromIsoString,
    feedback: CustomerFeedback.nullable(),
    files: z.array(UploadedFile),
    followers: z.array(UserAvatarInfo),
    id: z.number().int().positive(),
    iterations: z.array(IterationItem).transform((items) => (isNonEmpty(items) ? items : null)),
    labels: z.array(Label),
    links: Links,
    // TODO better type
    notes: z.any().array(),
    payment: Payment,
    pm: z.number().nullable(),
    points: z.number(),
    extra_points: z.number(),
    revisions: Revisions.transform((revisions) => revisions.map((revision, index) => ({
        ...revision,
        name: index > 0 ? `Revision ${index}` : 'First draft',
    }))),
    slides: z.number(),
    status: OrderStatus,
    style: SelectedStyle,
    tag_teams: z.array(AssignedDesignTeam),
    team: AssignedDesignTeam.nullable(),
    timeline: z.array(TimeLineItem),
    timezone: z.number().int(),
    title: z.string().nullable(),
    treatments: z.array(Treatment).transform(array.sort(ordTreatment)),
    collaboration: z.object({
        total_comments_count: z.number(),
        unread_comments_count: z.number().nullable(),
        last_read_comment_id: z.number().nullable(),
        last_read_at: DateFromIsoString.nullable(),
    }),
    is_confidential: z.boolean(),
});
export const orderTransform = (order) => ({
    ...order,
    hasCapacityLessTreatments: order.treatments.some((treatment) => {
        return (
        // TODO check if it's safe to depend on treatment name verbatim
        ['let us decide', 'additional design services'].includes(treatment.name.toLowerCase()) &&
            treatment.value > 0);
    }),
});
export const Order = BaseOrder.transform(orderTransform);
export const Role = z.enum(['admin', 'manager', 'owner']);
export const Member = z
    .object({
    // TODO this id can't be used for anything meaningful like fetching
    // usercards, so let's just forget it's existence until we fix BE
    id: z.number().int().positive(),
    user_id: z.number().int().positive(),
    role: Role,
    name: z.string(),
    email: Email,
    permission: z.enum(['edit', 'comment']),
})
    .transform(({ user_id, ...member }) => ({ ...member, id: user_id }));
export const CardPaymentMethod = z.object({
    brand: CardBrand,
    default: z.boolean(),
    expires: z.string().nullable(),
    id: z.number().int().positive(),
    last4: z.string(),
    type: z.literal('card'),
    vat: z.number().nonnegative(),
});
export const InvoicePaymentMethod = z.object({
    address: z.string(),
    company: z.string().nullable(),
    default: z.boolean(),
    id: z.number().int().positive(),
    poNumber: z.string().nullable(),
    type: z.literal('invoice'),
    vat: z.number().nonnegative(),
});
export const PaymentMethod = z.discriminatedUnion('type', [
    CardPaymentMethod,
    InvoicePaymentMethod,
]);
export function isCardPaymentMethod(method) {
    return method.type === 'card';
}
const ordPaymentMethod = pipe(boolean.Ord, ord.contramap((method) => method.default), ord.reverse);
export const Subscription = z.object({
    amount: z.number(),
    currency: z.string(),
    daily: z.number(),
    products: z
        .array(NumericIdNamePair.and(z.object({
        plan_id: z.number(),
    })))
        .nullable(),
    type: z.enum(['corporate', 'retainer', 'common']),
});
export const CustomerTreatments = z.record(z.record(z.tuple([z.number(), z.number()])));
export const Customer = z
    .object({
    company: z.string().nullable(),
    country: z.string().nullable(),
    credits: z.number().nonnegative(),
    email: Email,
    id: z.number().int().positive(),
    name: z.string(),
    last_active_at: DateFromIsoString.nullable(),
    avatar_link: z.string().nullable(),
    members: z.array(Member),
    owner_id: z.number().int().nonnegative().nullable(),
    payment_methods: z.array(PaymentMethod).transform(array.sort(ordPaymentMethod)),
    phone: z.string().nullable(),
    role: Role,
    subscription: Subscription.nullable(),
    treatments: CustomerTreatments.nullable(),
    styles: z.object({
        default_styles: z.array(DefaultStyle),
        custom_portal_styles: z.array(CustomPortalStyle),
        custom_styles: z.array(CustomStyle),
    }),
})
    .transform((customer) => {
    const sectionedStyleOptions = [];
    if (customer.styles.default_styles.length) {
        sectionedStyleOptions.push({
            name: 'Default styles',
            options: customer.styles.default_styles.map((style) => ({
                value: `styles.${style.id}`,
                name: style.name,
            })),
        });
    }
    if (customer.styles.custom_styles.length) {
        sectionedStyleOptions.push({
            name: 'Customer’s styles',
            options: customer.styles.custom_styles.map((style) => ({
                value: `customer_styles.${style.id}`,
                name: style.name,
            })),
        });
    }
    if (customer.styles.custom_portal_styles.length) {
        sectionedStyleOptions.push({
            name: 'Custom portal styles',
            options: customer.styles.custom_portal_styles.map((style) => ({
                value: `styles.${style.id}`,
                name: style.name,
            })),
        });
    }
    return {
        ...customer,
        styleOptions: sectionedStyleOptions.map((section) => section.options).flat(),
        sectionedStyleOptions,
    };
});
export const GetOrder = z
    .object({
    order: Order,
    customer: Customer,
})
    .transform(({ order, customer }) => ({ ...order, customer }));
export const OrderItemUser = z.object({
    has_credits: z.boolean(),
    name: z.string(),
    suspended: z.boolean(),
    suspend_reason: z.string().nullable().catch(null),
});
export const OrderItem = z.object({
    deadline: DateFromIsoString,
    id: z.number().int().positive(),
    manager: z
        .object({ id: z.number().int().positive(), initials: z.string(), name: z.string() })
        .nullable(),
    price: z.string(),
    revisions: z.number(),
    slides: z.number(),
    status: z.string(),
    subscribed: z.boolean(),
    tags: z.array(Label),
    tag_teams: z.array(z.object({ id: z.number().int().positive(), name: z.string() })),
    team: z.object({ id: z.number().int().positive(), name: z.string() }).nullable(),
    user: OrderItemUser,
    notifications: z.number().int().nonnegative(),
    viewers: z.undefined(),
    is_confidential: z.boolean(),
});
export const OrdersList = z.object({
    items: z.array(OrderItem),
    metadata: Metadata,
});
/**
 * Orders init
 */
export const Office = z.object({
    id: z.number(),
    city: z.string(),
    country: z.string(),
    country_code: z.string(),
    timezone_offset: z.number().int(),
    // TODO better type
    type: z.string(),
});
export const Visitors = z.object({
    order_id: z.number(),
    users: z.array(UserAvatarInfo),
});
export const VisitorsUpdated = z.object({
    order_id: z.number(),
    visitors: z.array(UserAvatarInfo),
});
export const Reason = z.string().transform((reason) => ({
    name: reason,
    value: reason,
    prefix: undefined,
}));
export const otherReasonPrefixes = ['other24slides:', 'otherByCustomer:'];
export const otherReasons = [
    { name: '24slides reason', value: '', prefix: otherReasonPrefixes[0] },
    { name: 'Customer request', value: '', prefix: otherReasonPrefixes[1] },
];
export const Reasons = z.object({
    adjust_deadline: z.array(z.string()),
    adjust_extra_capacity: z
        .array(Reason)
        .transform((mainReasons) => [...mainReasons, ...otherReasons]),
    order_cancellation: z.array(z.string()),
});
export const FilterOptions = z.object({
    status: z.array(NumericIdNamePair),
    manager: z.array(NumericIdNamePair),
    team: z.array(NumericIdNamePair),
    payment_status: z.array(NumericIdNamePair),
    customer_type: z.array(NumericIdNamePair),
    custom_portal: z.array(StringIdNamePair),
    organisation: z.array(NumericIdNamePair),
    completed: z.array(NumericIdNamePair),
});
export const Init = z
    .object({
    addons: z.array(Addon),
    managers: z.array(Manager),
    offices: z.array(Office),
    reasons: Reasons,
    statuses: z.object({
        order: z.array(OrderStatusOption).nonempty(),
        order_legacy: z.array(OrderStatusLegacyOption),
        payment: z.array(PaymentStatus),
    }),
    filter_options: FilterOptions,
    teams: z.array(DesignTeam),
    visitors: z.array(Visitors),
})
    .transform(({ visitors, ...init }) => ({
    ...init,
    visitors: visitors.reduce((visitorsMap, { order_id, users }) => {
        visitorsMap[order_id] = users;
        return visitorsMap;
    }, {}),
}));
/**
 * Calculate price
 */
export const CalculatePrice = z.object({
    amount: z.number().nonnegative(),
    coupon: z.string().nullable(),
    discount: Bounty.nullable(),
    unit: z.number().nonnegative(),
    vat: z.number().nonnegative(),
    points: z.number().nonnegative(),
});
export const OrderCollaboration = z.object({
    checklist_items_done: z.number().int().nonnegative(),
    checklist_items_total: z.number().int().nonnegative(),
    unread_comments_count: z.number().int().nonnegative(),
    total_comments_count: z.number().int().nonnegative(),
});
export const PartialOrdersBoardItem = z.object({
    id: z.number().int().positive(),
    title: z.string(),
    tags: z.array(Label),
    deadline: DateFromIsoString,
    //Filtering
    status: z.number().int().nonnegative(),
    customer_type: z.number(),
    manager: z.number().nullable(),
    team: z.number().nullable(),
    payment_status: z.number(),
    organisation: z.number().nullable(),
    custom_portal: z.string().nullable(),
    designers: z.array(UserAvatarInfo),
    created_at: DateFromIsoString,
    updated_at: DateFromIsoString,
});
export const OrdersBoardItemUpdate = z.object({
    order: PartialOrdersBoardItem,
});
export const OrdersBoardItem = PartialOrdersBoardItem.extend({
    collaboration: OrderCollaboration,
    notifications: z.number().int().nonnegative(),
});
export const OrdersBoard = z.object({
    items: z.array(OrdersBoardItem),
});
