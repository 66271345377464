import { $applyNodeReplacement, TextNode, } from 'lexical';
import { z } from 'zod';
import { cx, nonNullable } from '~/common/utils';
import { queryClient } from '~/root';
import { qk } from '~/root/query-keys';
export const MentionEntity = z.object({
    id: z.number(),
    type: z.enum(['user', 'team']),
    name: z.string(),
});
const MENTION_NODE_DATA_ATTRIBUTE = 'data-lexical-mention';
function convertMentionElement(domNode) {
    const entityJson = domNode.getAttribute(MENTION_NODE_DATA_ATTRIBUTE);
    const textContent = domNode.textContent;
    if (!entityJson || !textContent) {
        return null;
    }
    const node = $createMentionNode(JSON.parse(entityJson));
    return { node };
}
export class MentionNode extends TextNode {
    static getType() {
        return 'mention';
    }
    static clone(node) {
        return new MentionNode(node.__entity, node.__key);
    }
    static importJSON(serializedNode) {
        const node = $createMentionNode(serializedNode.entity);
        node.setTextContent(serializedNode.text);
        node.setFormat(serializedNode.format);
        node.setDetail(serializedNode.detail);
        node.setMode(serializedNode.mode);
        node.setStyle(serializedNode.style);
        return node;
    }
    constructor(entity, key) {
        super('@' + entity.name, key);
        this.__entity = entity;
    }
    exportJSON() {
        return {
            ...super.exportJSON(),
            entity: this.__entity,
            type: 'mention',
            version: 1,
        };
    }
    createDOM(config) {
        // TODO this certainly doesn't look like a good way, but it doesn't seem
        // like lexical has a good way of passing custom metadata from the composer
        // context to custom nodes
        const { id, team } = nonNullable(queryClient.getQueryData(qk.init));
        const mentionedAsUser = this.__entity.type === 'user' && this.__entity.id === id;
        const mentionedAsTeam = this.__entity.type === 'team' && this.__entity.id === (team === null || team === void 0 ? void 0 : team.id);
        const areYouMentioned = mentionedAsUser || mentionedAsTeam;
        const dom = super.createDOM(config);
        dom.className = cx('inline-block px-[2px] rounded', areYouMentioned ? 'bg-accent-50 text-accent-400' : 'bg-secondary-100 text-secondary-400');
        dom.setAttribute(MENTION_NODE_DATA_ATTRIBUTE, JSON.stringify(this.__entity));
        return dom;
    }
    exportDOM() {
        const element = document.createElement('span');
        element.setAttribute(MENTION_NODE_DATA_ATTRIBUTE, JSON.stringify(this.__entity));
        element.textContent = this.__text;
        return { element };
    }
    static importDOM() {
        return {
            span: (domNode) => {
                if (!domNode.hasAttribute(MENTION_NODE_DATA_ATTRIBUTE)) {
                    return null;
                }
                return {
                    conversion: convertMentionElement,
                    priority: 1,
                };
            },
        };
    }
    isTextEntity() {
        return true;
    }
    canInsertTextBefore() {
        return false;
    }
    canInsertTextAfter() {
        return false;
    }
}
export function $createMentionNode(entity) {
    const mentionNode = new MentionNode(entity);
    mentionNode.setMode('segmented').toggleDirectionless();
    return $applyNodeReplacement(mentionNode);
}
export function $isMentionNode(node) {
    return node instanceof MentionNode;
}
