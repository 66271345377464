import { jsx as _jsx } from "react/jsx-runtime";
import { faChevronDown, faTimesCircle } from '@fortawesome/pro-solid-svg-icons';
import { DateRangePicker, IconBox, Input, Popover } from '~/common/components';
import { formatYearMonthDay, isNonNullable } from '~/common/utils';
import { useParsedQuery } from '../hooks';
export const DateRangeFilter = () => {
    const { queryParams, updateFilters } = useParsedQuery();
    const dateRangeFilterProps = ({ onClose }) => ({
        value: {
            start: queryParams.filter.from ? new Date(queryParams.filter.from) : new Date(),
            end: queryParams.filter.to ? new Date(queryParams.filter.to) : new Date(),
        },
        onChange: ({ start, end }) => {
            updateFilters((prev) => ({
                ...prev,
                from: formatYearMonthDay(start),
                to: formatYearMonthDay(end),
            }));
            onClose();
        },
    });
    const dateRangeInputDates = [queryParams.filter.from, queryParams.filter.to].filter(isNonNullable);
    const dateRangeInputValue = dateRangeInputDates.length
        ? dateRangeInputDates.map((date) => date.split('-').reverse().join('.')).join(' - ')
        : '';
    return (_jsx(Popover, { placement: "bottom-start", trigger: (props) => (_jsx(Input, { ...props, className: "w-27", value: dateRangeInputValue, readOnly: true, placeholder: "Choose period", theme: dateRangeInputValue ? 'filter' : 'dark', size: "small", children: dateRangeInputValue ? (_jsx(IconBox, { size: "s", className: "cursor-pointer hover:scale-110", icon: faTimesCircle, onClick: (e) => {
                    e.preventDefault();
                    updateFilters((prev) => ({ ...prev, from: undefined, to: undefined }));
                } })) : (_jsx(IconBox, { size: "s", icon: faChevronDown })) })), content: (props) => _jsx(DateRangePicker, { ...dateRangeFilterProps(props) }) }));
};
