import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import { Button, useModalContext } from '~/common/components';
import { Files, Section } from '~/orders/Order';
import { DownloadButton } from './DownloadButton';
import { FileItem } from './FileItem';
export const FilesSection = ({ files, id }) => {
    const { modalOpener } = useModalContext();
    const shortFiles = useMemo(() => files.slice(0, 5), [files]);
    if (!files.length) {
        return null;
    }
    return (_jsxs(Section, { type: "files", children: [_jsx("div", { className: "space-y-1", children: shortFiles.map((file, key) => (_jsx(FileItem, { ...file }, key))) }), _jsxs("div", { className: "flex items-center justify-between mt-2", children: [_jsxs(Button, { onClick: modalOpener(Files, { files, orderId: id }), color: "grey", children: ["See all ", files.length, " files"] }), _jsx(DownloadButton, { orderId: id, children: "Download all as ZIP" })] })] }));
};
