import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { faEnvelope, faLock } from '@fortawesome/pro-regular-svg-icons';
import { differenceInHours } from 'date-fns';
import { Badge, DateDistance, IconBox } from '~/common/components';
import { cx } from '~/common/utils';
import { OrderViewers } from './OrderViewers';
import { TeamsView } from './TeamsView';
const HOURS_DIFF_CRITICAL = 3;
const HOURS_DIFF_WARNING = 24;
export const tableConfig = {
    columns: [
        {
            key: 'id',
            name: 'ID',
            width: '106px',
            getClassName: () => 'overflow-visible flex items-center gap-[6px]',
            cellRenderer: ({ cellData, rowData }) => (_jsxs(_Fragment, { children: [_jsx("span", { children: cellData }), rowData.is_confidential && (_jsx(IconBox, { icon: faLock, className: "relative top-[2px] text-greyscale-500", "data-tt": "This order is restricted" }))] })),
        },
        {
            key: 'user',
            name: 'Customer',
            width: 'minmax(100px, 1fr)',
            cellRenderer: ({ cellData, rowData }) => (_jsxs(_Fragment, { children: [_jsx("span", { "data-tt": cx(cellData.suspend_reason), className: "truncate h-[20px]", children: cellData.name }), _jsx("div", { className: "flex items-center absolute top-[38px] h-[18px] gap-[4px]", children: rowData.tags.map((item) => (_jsx(Badge, { color: item.type, size: "small", children: item.value }, item.value))) })] })),
            getClassName: ({ cellData }) => cx(cellData.suspended && 'text-danger-400', 'relative flex overflow-visible'),
        },
        {
            key: 'notifications',
            name: '',
            cellRenderer: ({ cellData }) => !!cellData && (_jsxs("div", { "data-tt": cellData + ' new notifications', "data-tt-placement": "right", className: "relative h-2 w-2 top-0 z-[2]", children: [_jsx(IconBox, { size: "s", icon: faEnvelope, className: "text-greyscale-500" }), _jsx("div", { className: cx('absolute right-[-1px] top-[1px] h-[6px] w-[6px] rounded-full pointer-events-none', 'bg-primary-300 border border-solid border-greyscale-0') })] })),
            width: '40px',
        },
        {
            key: 'slides',
            name: 'Slides',
            width: '90px',
        },
        {
            key: 'status',
            name: 'Order Status',
            width: '162px',
        },
        {
            key: 'revisions',
            name: 'Revisions',
            width: '112px',
        },
        {
            key: 'price',
            name: 'Price',
            getClassName: () => 'overflow-visible',
            width: '80px',
        },
        {
            key: 'manager',
            name: 'PM',
            cellRenderer: ({ cellData }) => { var _a; return (_a = cellData === null || cellData === void 0 ? void 0 : cellData.name) !== null && _a !== void 0 ? _a : '—'; },
            width: '12%',
        },
        {
            key: 'team',
            name: 'Design Team',
            cellRenderer: ({ cellData, rowData }) => cellData ? _jsx(TeamsView, { name: cellData.name, tag_teams: rowData.tag_teams }) : '—',
            width: '12%',
        },
        {
            key: 'deadline',
            name: 'Deadline',
            cellRenderer: ({ cellData }) => _jsx(DateDistance, { to: cellData }),
            getClassName: ({ cellData, rowData }) => {
                const isClosed = ['Cancelled', 'Completed', 'Waiting for customer'].includes(rowData.status);
                const now = new Date();
                const diffHours = differenceInHours(cellData, now);
                if (isClosed) {
                    return '';
                }
                if (diffHours <= HOURS_DIFF_CRITICAL) {
                    return 'text-danger-400';
                }
                if (diffHours <= HOURS_DIFF_WARNING) {
                    return 'text-warning-400';
                }
                return 'text-greyscale-500';
            },
            width: '146px',
        },
        {
            key: 'viewers',
            name: '',
            cellRenderer: ({ rowData }) => _jsx(OrderViewers, { id: rowData.id }),
            width: '80px',
        },
    ],
    sortable: ['id', 'slides', 'revisions', 'price', 'deadline'],
    getCellClassName: () => 'h-8 items-baseline pt-[14px]',
};
