import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { boolean, readonlyRecord } from 'fp-ts';
import { constNull, pipe } from 'fp-ts/function';
import { useState } from 'react';
import { Button, LineBreaks, ParseLinks, useModalContext } from '~/common/components';
import { cx, isNonNullable, record } from '~/common/utils';
import { Brief, Section } from '~/orders/Order';
import css from './BriefSection.module.scss';
export const BriefSection = ({ brief }) => {
    const [showFullBrief, setShowFullBrief] = useState(false);
    const { modalOpener } = useModalContext();
    const makeQA = ([question, answer]) => (_jsxs("div", { children: [_jsx("p", { className: "font-brand-b1 mb-[4px] text-greyscale-400", children: question }), (answer === null || answer === void 0 ? void 0 : answer.includes('http')) ? (_jsx(ParseLinks, { className: "flex flex-wrap font-brand-b1", children: answer })) : (_jsx("p", { className: "font-brand-b1", children: answer !== null && answer !== void 0 ? answer : '—' }))] }, question));
    return (_jsx(Section, { type: "brief", children: _jsxs("div", { className: "space-y-3", children: [_jsxs("div", { className: "space-y-2", children: [_jsx("h3", { className: "font-brand-t1r", children: "Brief" }), _jsx(LineBreaks, { ref: (ref) => setShowFullBrief(ref ? ref.scrollHeight > ref.clientHeight : false), className: cx('font-brand-b1', css.brief), renderParagraph: (paragraph) => (_jsx(ParseLinks, { className: "flex flex-wrap", children: paragraph })), children: brief.brief }), showFullBrief && (_jsx(Button, { onClick: modalOpener(Brief, { brief }), color: "secondary", children: "See full brief" }))] }), brief.questions && (_jsxs("div", { className: "space-y-2", children: [_jsx("h3", { className: "font-brand-t1r", children: "Questions" }), record.entries(brief.questions).map(makeQA)] })), pipe(brief.custom, readonlyRecord.filter(isNonNullable), readonlyRecord.isEmpty, boolean.fold(() => (_jsxs("div", { className: "space-y-2", children: [_jsx("h3", { className: "font-brand-t1r", children: "Custom" }), record.entries(brief.custom).map(makeQA)] })), constNull))] }) }));
};
