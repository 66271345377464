/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
export const qk = {
    init: ['init'],
    timezones: ['timezones'],
    usercards: ['usercards'],
    uploadFile: (fileKey) => ['upload_file', fileKey],
    countries: ['init', 'countries'],
    notifications: ['notifications'],
    notificationsReadAll: ['notifications', 'read-all'],
    userActivity: ['user', 'activity'],
    invitationsToken: ['invitations', 'token'],
    collaborationAll: (id) => ['orders', 'item', { id }, 'collaboration'],
    collaborationMessages: (id) => ['orders', 'item', { id }, 'collaboration', 'messages'],
    collaborationUpdateMessage: (id) => ['orders', 'item', { id }, 'collaboration', 'messages', 'update'],
    collaborationReadMessage: (id) => ['orders', 'item', { id }, 'collaboration', 'messages', 'read'],
    collaborationSendMessage: (id) => ['orders', 'item', { id }, 'collaboration', 'messages', 'send'],
    collaborationRemoveMessage: (id) => ['orders', 'item', { id }, 'collaboration', 'messages', 'remove'],
    collaborationChecklist: (id) => ['orders', 'item', { id }, 'collaboration', 'checklist'],
    collaborationChecklistDelete: (id) => ['orders', 'item', { id }, 'collaboration', 'checklist', 'delete'],
    collaborationChecklistToggle: (id) => ['orders', 'item', { id }, 'collaboration', 'checklist', 'toggle'],
    collaborationChecklistUpdate: (id) => ['orders', 'item', { id }, 'collaboration', 'checklist', 'update'],
    collaborationMentions: (id) => ['orders', 'item', { id }, 'collaboration', 'mentions'],
    ordersAll: ['orders'],
    ordersInit: ['orders', 'init'],
    ordersList: ['orders', 'list'],
    ordersBoard: ['orders', 'board'],
    orderAll: (id) => ['orders', 'item', { id }],
    orderBase: (id) => ['orders', 'item', { id }, 'base'],
    orderActivity: (id) => ['orders', 'item', { id }, 'activity'],
    orderUpdate: (id) => ['orders', 'item', { id }, 'update'],
    orderDelete: (id) => ['orders', 'item', { id }, 'delete'],
    orderChangeStatus: (id) => ['orders', 'item', { id }, 'changeStatus'],
    orderFiles: (id) => ['orders', 'item', { id }, 'files'],
    orderUpdateIteration: (id) => ['orders', 'item', { id }, 'updateIteration'],
    orderCalcPrice: (id) => ['orders', 'item', { id }, 'calcPrice'],
    orderManualCharge: (id) => ['orders', 'item', { id }, 'manualCharge'],
    orderChatMessages: (id) => ['orders', 'item', { id }, 'messages'],
    orderChatUpdateMessage: (id) => ['orders', 'item', { id }, 'messages', 'update'],
    orderChatSendMessage: (id) => ['orders', 'item', { id }, 'messages', 'send'],
    orderChatRemoveMessage: (id) => ['orders', 'item', { id }, 'messages', 'remove'],
    addAnnotationComment: (id) => ['orders', 'item', { id }, 'revisions', 'addAnnotationComment'],
    removeAnnotationComment: (id) => ['orders', 'item', { id }, 'reivsions', 'removeAnnotationComment'],
    resolveAnnotation: (id) => ['orders', 'item', { id }, 'revisions', 'updateAnnotation'],
    massResolveSlide: (id, slideId) => ['orders', 'item', { id }, 'revisions', 'resolve', 'slide', { slideId }],
    massResolveRevision: (id) => ['orders', 'item', { id }, 'revisions', 'resolve', 'revision'],
    addRevision: (id) => ['orders', 'item', { id }, 'revisions', 'create'],
    updateRevision: (id) => ['orders', 'item', { id }, 'revisions', 'update'],
    usersInit: ['users', 'init'],
    usersList: ['users', 'list'],
    user: (id) => ['users', { id }],
    teamsInit: ['teams', 'init'],
    teamsList: ['teams', 'list'],
    team: (id) => ['teams', { id }],
    officesInit: ['offices', 'init'],
    officesList: ['offices', 'list'],
    office: (id) => ['offices', { id }],
    rmsAll: ['rms'],
    rmsInit: ['rms', 'init'],
    rmsOrders: ['rms', 'orders'],
    rmsOrder: (id) => ['rms', 'orders', { id }],
    rmsTotals: ['rms', 'totals'],
    rmsUtilization: ['rms', 'utilization'],
    rmsBreakdown: ['rms', 'breakdown'],
    rmsCapacityChangeDelete: ['rms', 'capacity', 'changes', 'delete'],
    shiftsAll: ['shifts'],
    shiftsInit: ['shifts', 'init'],
    customersAll: ['customers'],
    customersInit: ['customers', 'init'],
    customersList: ['customers', 'list'],
    customer: (id) => ['customers', 'item', { id }],
    customerMembers: (id) => ['customers', 'item', { id }, 'members'],
    customerActivity: (id) => ['customers', 'item', { id }, 'activity'],
    subscriptionsAll: ['subscriptions'],
    subscriptionsInit: ['subscriptions', 'init'],
    subscriptionsList: ['subscriptions', 'list'],
    subscriptionAll: ['subscriptions', 'item'],
    subscription: (id) => ['subscriptions', 'item', { id }],
    subscriptionMembers: (id) => ['subscriptions', 'item', { id }, 'members'],
    subscriptionActivity: (id) => ['subscriptions', 'item', { id }, 'activity'],
    organisationsAll: ['organisations'],
    organisationsInit: ['organisations', 'init'],
    organisationsList: ['organisations', 'list'],
    organisation: (id) => ['organisations', { id }],
    organisationStats: (id) => ['organisations', { id }, 'stats'],
    organisationActivity: (id) => ['organisations', { id }, 'activity'],
};
