import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faStar, faStarHalfStroke } from '@fortawesome/pro-solid-svg-icons';
import { nonEmptyArray } from 'fp-ts';
import { IconBox } from '~/common/components';
import { record } from '~/common/utils';
import { useOrderData } from '~/orders/hooks';
import { Section } from './Section';
const Stars = ({ rating, title }) => (_jsxs("div", { className: "flex flex-col gap-[4px]", children: [_jsx("span", { className: "font-brand-b1 text-greyscale-500", children: title }), rating ? (_jsx("div", { className: "flex items-center gap-1 w-max", children: nonEmptyArray.range(1, 5).map((star) => (_jsx(IconBox, { size: "s", className: Math.ceil(rating) >= star ? 'text-warning-400' : 'text-greyscale-300', icon: rating < star && Math.ceil(rating) === star ? faStarHalfStroke : faStar }, star))) })) : (_jsx("div", { "data-tt": "Customer did not leave this rating", className: "flex items-center gap-1 w-max", children: nonEmptyArray.range(1, 5).map((key) => (_jsx(IconBox, { size: "s", className: "text-greyscale-300", icon: faStar }, key))) }))] }));
export const FeedbackSection = () => {
    const order = useOrderData();
    if (order.status === 'Completed' && order.feedback) {
        const { ratings, qa } = order.feedback;
        return (_jsxs(Section, { color: "gold", type: "feedback", children: [_jsx("div", { className: "grid grid-cols-2 gap-4 p-2", children: record.entries(ratings).map(([title, rating], key) => (_jsx(Stars, { rating: rating, title: title }, key))) }), _jsx("div", { className: "flex flex-col gap-2 p-2", children: record.entries(qa).map(([question, answer], key) => (_jsxs("div", { className: "flex flex-col gap-1", children: [_jsx("span", { className: "font-brand-b1 text-greyscale-500", children: question }), _jsx("span", { className: "font-brand-b1 text-greyscale-600", children: answer !== null && answer !== void 0 ? answer : '—' })] }, key))) })] }));
    }
    return null;
};
