import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { Collapse, IconBox } from '~/common/components';
import { cx } from '~/common/utils';
import { isNavigationSection, useSettings } from '~/root';
import css from './NavigationItem.module.scss';
const useOpened = (path) => {
    const entries = useSettings((store) => store.entries);
    const setEntries = useSettings((store) => store.setEntries);
    const opened = entries.includes(path);
    const toggleOpened = () => setEntries((v) => (v.includes(path) ? v.filter((v) => v !== path) : [...v, path]));
    return { toggleOpened, opened };
};
const Circle = ({ filled }) => {
    return (_jsx("div", { className: css.circleWrapper, children: _jsx("div", { className: cx(css.circle, { [css.filled]: filled }) }) }));
};
export const NavigationItem = (props) => {
    const { opened, toggleOpened } = useOpened(props.path);
    const { pathname } = useLocation();
    const isActive = pathname.startsWith(props.path);
    const isSection = isNavigationSection(props);
    const handleClick = (e) => {
        e.preventDefault();
        toggleOpened();
    };
    const onClick = isSection ? handleClick : undefined;
    return (_jsxs("li", { className: cx(css.item, { [css.active]: isActive, [css.opened]: opened }), children: [_jsxs(RouterLink, { className: css.link, to: props.path, onClick: onClick, children: [props.icon && (_jsx(IconBox, { size: "m", className: cx(css.icon, 'text-inherit'), icon: props.icon })), !props.icon && _jsx(Circle, { filled: isActive }), _jsx("span", { children: props.label }), isSection && _jsx(IconBox, { size: "s", className: css.chevron, icon: faChevronLeft })] }), isSection && (_jsx(Collapse, { in: opened, children: _jsx("ul", { className: css.innerList, children: props.items.map((item, key) => (_jsx(NavigationItem, { ...item }, key))) }) }))] }));
};
