import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { faLongArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { addDays, addHours, format, isWithinInterval, parse } from 'date-fns';
import { useForm, validator } from 'formoid';
import { useLayoutEffect, useMemo, useState } from 'react';
import { Badge, Banner, Button, CountryFlag, Datepicker, Errors, IconBox, Input, InputField, Popover, SelectField, Show, } from '~/common/components';
import { customValidator, cx, formatDateDistance, formatDayFullMonthYear } from '~/common/utils';
import { useSortedOrderCountries } from '~/orders/hooks';
import { appendOtherReason, customReasonValidator, getTimezoneDate, isOtherReason, } from '~/orders/utils';
const MAX_DATE_RANGE = 60;
const getValidInterval = () => ({
    minDate: new Date(),
    maxDate: addDays(new Date(), MAX_DATE_RANGE),
});
const TIME_FORMAT = 'HH:mm';
const getTimezonedCountryTime = (date, { country_code, timezone_offset }) => ({
    date,
    country_code,
    time: getTimezoneDate(date, timezone_offset),
});
const DeadlineTime = ({ time, date, active }) => (_jsxs("div", { children: [_jsx("div", { className: cx('font-brand-t4m', active && 'text-secondary-400'), children: time }), _jsx("div", { className: "font-brand-c1r text-greyscale-400", children: formatDateDistance(date) })] }));
const DeadlineCompare = ({ prev, next }) => (_jsxs("div", { className: "flex space-x-1", children: [_jsx(CountryFlag, { countryCode: prev.country_code, className: "mt-[3px]" }), _jsx(DeadlineTime, { time: prev.time, date: prev.date }), _jsxs(Show, { if: prev.time !== next.time, children: [_jsx(IconBox, { size: "m", icon: faLongArrowRight, className: "text-greyscale-500" }), _jsx(DeadlineTime, { time: next.time, date: next.date, active: true })] })] }));
const DateTimePicker = ({ value, onChange, onBlur, ...props }) => {
    const [time, setTime] = useState(format(value, TIME_FORMAT));
    const [error, setError] = useState(null);
    const errors = error ? [error] : props.errors;
    const handleTimeBlur = (e) => {
        const text = e.target.value;
        setTime(text);
        try {
            const nextValue = parse(text, TIME_FORMAT, value);
            if (nextValue.toString() !== 'Invalid Date') {
                onChange(nextValue);
                onBlur();
                setError(null);
            }
            else {
                setError('Invalid time');
            }
        }
        catch (e) { }
    };
    useLayoutEffect(() => {
        setTime(format(value, TIME_FORMAT));
    }, [value]);
    return (_jsxs("div", { children: [_jsxs("div", { className: "flex items-start space-x-1", children: [_jsx(Popover, { trigger: (props) => (_jsx(Input, { ...props, error: !!errors, type: "text", value: formatDayFullMonthYear(value), size: "small", readOnly: true })), content: ({ onClose }) => (_jsx(Datepicker, { ...getValidInterval(), value: value, onChange: (date) => {
                                onChange(date);
                                onClose();
                                onBlur();
                            } })) }), _jsx(Input, { value: time, onChange: (e) => setTime(e.target.value), onBlur: handleTimeBlur, error: !!errors, className: "w-10 min-w-[80px]", size: "small" })] }), errors && _jsx(Errors, { errors: errors, color: true })] }));
};
const presets = [12, 24, 48];
const dateValidator = (interval) => {
    return validator.fromPredicate((date) => isWithinInterval(date, { start: interval.minDate, end: interval.maxDate }), `Deadline should be between now and +${MAX_DATE_RANGE} days`);
};
export const AdjustDeadline = (props) => {
    var _a;
    const reasonOptions = appendOtherReason(props.reasons.adjust_deadline);
    const [selectedPreset, setSelectedPreset] = useState(null);
    const { fieldProps, handleSubmit, values } = useForm({
        initialValues: {
            value: props.deadline,
            reason: (_a = props.reason) !== null && _a !== void 0 ? _a : null,
            customReason: null,
        },
        validationStrategy: 'onBlur',
        validators: (values) => ({
            customReason: customReasonValidator(values.reason),
            reason: customValidator.required(),
            value: dateValidator(getValidInterval()),
        }),
    });
    const submit = () => handleSubmit(({ reason, customReason, value }) => {
        const selectedReason = isOtherReason(reason) ? customReason : reason;
        if (selectedReason) {
            return Promise.resolve(props.onApply({ reason: selectedReason, value })).then(props.onClose);
        }
        return Promise.reject(null);
    });
    const countries = useSortedOrderCountries();
    const patchedCountries = useMemo(() => {
        return countries.map((country) => ({
            prev: getTimezonedCountryTime(props.deadline, country),
            next: getTimezonedCountryTime(values.value, country),
        }));
    }, [countries, props.deadline, values.value]);
    const handlePresetClick = (preset) => {
        fieldProps('value').onChange(addHours(props.deadline, preset));
        setSelectedPreset(preset);
    };
    const dateTimePickerProps = {
        ...fieldProps('value'),
        onChange: (value) => {
            setSelectedPreset(null);
            fieldProps('value').onChange(value);
        },
    };
    const unchanged = values.value.getTime() === props.deadline.getTime();
    return (_jsxs(_Fragment, { children: [_jsx(Banner, { color: "warning", className: "mb-2", children: "Please adjust the order price manually following the new turnaround. Automatic pricing change is not supported" }), _jsxs("div", { className: "flex font-brand-b1 text-greyscale-600", children: [_jsxs("div", { className: "w-32", children: [_jsx("p", { className: "mb-[4px]", children: "Adjust deadine" }), _jsx(DateTimePicker, { ...dateTimePickerProps }), _jsx("div", { className: "flex space-x-[5px] mt-1", children: presets.map((preset) => (_jsxs(Badge.Button, { color: preset === selectedPreset ? 'primary' : 'lighter-grey', onClick: () => handlePresetClick(preset), children: ["+", preset, "h"] }, preset))) }), _jsx("p", { className: "mt-3 mb-[4px]", children: "Reason" }), _jsx(SelectField, { ...fieldProps('reason'), options: reasonOptions, placeholder: "Choose from list" }), isOtherReason(values.reason) && (_jsx(InputField, { ...fieldProps('customReason'), className: "mt-1", placeholder: "Describe your reason", type: "text" }))] }), _jsx("div", { className: "ml-5 w-[316px] space-y-3", children: patchedCountries.map((country, index) => (_jsx(DeadlineCompare, { ...country }, index))) })] }), _jsxs("div", { className: "flex items-center justify-end space-x-2 mt-2", children: [_jsx(Button, { color: "grey", onClick: props.onClose, children: "Cancel" }), _jsx(Button, { "data-tt": cx(unchanged && 'Deadline was not changed'), "data-tt-placement": "bottom", color: "secondary", onClick: submit, disabled: unchanged, children: "Apply" })] })] }));
};
