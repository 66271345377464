import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faShapes } from '@fortawesome/pro-regular-svg-icons';
import { LayoutContent, NotFound, withModalContext } from '~/common/components';
import { combineWrappers } from '~/common/kits/context';
import { is404 } from '~/common/utils';
import { useInit, useOrder, useOrderData } from '~/orders/hooks';
import { useCountries } from '~/root';
import { ORDERS_URL } from '..';
import { BriefSection, Chat, CustomerSection, FeedbackSection, FilesSection, Header, InformationBar, InformationSection, RevisionSection, withDistributionContext, } from '.';
const withWrappers = combineWrappers(withModalContext, withDistributionContext);
const OrderContent = withWrappers(() => {
    const order = useOrderData();
    const { brief, customer, files } = order;
    return (_jsxs(LayoutContent, { className: "overflow-x-hidden", children: [_jsx(Header, {}), _jsxs("div", { className: "h-full overflow-auto flex gap-2", children: [_jsx("div", { className: "ml-auto max-w-[280px] min-w-[150px] w-full sticky top-0 overflow-auto overscroll-contain h-[calc(100vh-157px)]", children: _jsx(InformationBar, {}) }), _jsxs("div", { className: "max-w-[712px] min-w-[400px] w-full space-y-2", children: [_jsx(FeedbackSection, {}), _jsx(RevisionSection, {}), _jsx(BriefSection, { brief: brief }), _jsx(FilesSection, { id: order.id, files: files }), _jsx(CustomerSection, {}), _jsx(InformationSection, {})] }), _jsx("div", { className: "mr-auto flex max-w-[424px] min-w-[220px] w-full sticky top-0 h-[calc(100vh-157px)]", children: _jsx(Chat, { customer: customer }) })] })] }));
});
export const Order = () => {
    const init = useInit();
    const order = useOrder();
    const countries = useCountries();
    if (is404(order.error)) {
        return (_jsx(NotFound, { icon: faShapes, text: "There's no order with this id", safeLocation: ORDERS_URL }));
    }
    if (!order.data || !init.data || !countries.data) {
        return null;
    }
    return _jsx(OrderContent, {});
};
