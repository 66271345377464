import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useForm } from 'formoid';
import { constNull, identity } from 'fp-ts/function';
import { useMemo, useState } from 'react';
import { ModalHeader, ModalShell, Show } from '~/common/components';
import { formatDateDistance, formatYearMonthDay, getDateWithoutTime, record, } from '~/common/utils';
import { useInitData, useOrderData, useUpdateOrder, } from '~/orders/hooks';
import { CustomerCapacity, Deadline, DesignCapacity } from '~/orders/Order';
import { capacityEndDateValidator, capacityStartDateValidator } from '~/orders/utils';
export const EditDeliveryInfo = (props) => {
    var _a, _b, _c, _d;
    const init = useInitData();
    const order = useOrderData();
    const updateOrder = useUpdateOrder();
    const [extraCapacityPayload, setExtraCapacityPayload] = useState();
    const [deadlinePayload, setDeadlinePayload] = useState();
    const [currentDeadline, setCurrentDeadline] = useState(order.deadline);
    const currentDeadlineText = useMemo(() => formatDateDistance(currentDeadline), [currentDeadline]);
    const initialValues = {
        'designCapacity.order': order.points,
        'designCapacity.extra': 0,
        'designCapacity.total': order.points,
        // TODO timezone should this get the date of order.created based on UTC time ?
        'customerCapacity.start': (_b = (_a = order.customer_capacity) === null || _a === void 0 ? void 0 : _a.start) !== null && _b !== void 0 ? _b : getDateWithoutTime(order.created),
        'customerCapacity.end': (_d = (_c = order.customer_capacity) === null || _c === void 0 ? void 0 : _c.end) !== null && _d !== void 0 ? _d : getDateWithoutTime(),
        deadline: currentDeadlineText,
    };
    const { fieldProps, handleSubmit, isSubmitting, values } = useForm({
        initialValues,
        validationStrategy: 'onBlur',
        validators: (values) => order.customer.subscription !== null
            ? {
                ...record.map(initialValues, constNull),
                'customerCapacity.start': capacityStartDateValidator(order.created),
                'customerCapacity.end': capacityEndDateValidator(values['customerCapacity.start']),
            }
            : record.map(initialValues, constNull),
    });
    const canSubmit = [
        extraCapacityPayload !== undefined,
        deadlinePayload !== undefined,
        values['customerCapacity.start'] !== null,
        values['customerCapacity.end'] !== null,
    ].some(identity);
    const submit = () => handleSubmit((values) => {
        const payload = {
            extra_points: extraCapacityPayload,
            deadline: deadlinePayload,
            customer_capacity: order.customer.subscription
                ? {
                    start: formatYearMonthDay(values['customerCapacity.start']),
                    end: formatYearMonthDay(values['customerCapacity.end']),
                }
                : undefined,
        };
        return updateOrder(payload).then(props.onClose);
    });
    const handleApplyExtraCapacity = (payload) => {
        fieldProps('designCapacity.extra').onChange(payload.value);
        fieldProps('designCapacity.total').onChange(fieldProps('designCapacity.total').value + payload.value);
        setExtraCapacityPayload(payload);
    };
    const handleApplyDeadline = (payload) => {
        setCurrentDeadline(payload.value);
        fieldProps('deadline').onChange(formatDateDistance(payload.value));
        setDeadlinePayload(payload);
    };
    return (_jsxs(_Fragment, { children: [_jsx(ModalHeader, { onClose: props.onClose, title: "Delivery" }), _jsx(ModalShell, { className: "p-3 w-[712px]", disabled: !canSubmit, disabledHint: "You haven't changed anything", loading: isSubmitting, onClose: props.onClose, onSubmit: submit, children: _jsxs("div", { className: "flex items-start space-x-2 w-full", children: [_jsxs("div", { className: "w-1/2 space-y-3", children: [_jsx(DesignCapacity, { fieldProps: fieldProps, onApply: handleApplyExtraCapacity, reasons: init.reasons }), _jsx(Show, { if: !!order.customer.subscription, children: _jsx(CustomerCapacity, { fieldProps: fieldProps }) })] }), _jsx("div", { className: "w-1/2", children: _jsx(Deadline, { currentDeadlineText: currentDeadlineText, deadline: (deadlinePayload === null || deadlinePayload === void 0 ? void 0 : deadlinePayload.value) || order.deadline, onApply: handleApplyDeadline, reasons: init.reasons, reason: deadlinePayload === null || deadlinePayload === void 0 ? void 0 : deadlinePayload.reason }) })] }) })] }));
};
